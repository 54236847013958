<template>
  <div class="notify-box">
    <div class="notify__date--area">
      <span class="notify__day">{{dateTody.date}}</span>
      <span class="notify__month">{{dateTody.month}}月</span>
    </div>
    <div class="notify-driver"></div>
    <swiper ref="notifySwiper" :options="swiperOptions">
      <swiper-slide class="notify-swiper-item" v-for="(item, index) in list" :key="index">
        <div class="notify-swiper-content" >
          <h2 class="van-ellipsis">{{item.xxbt}}</h2>
          <p class="van-ellipsis">{{item.xxfbt}}</p>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import { getHlwFbxxPageByJgbm } from '@/api/login'

import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'


function addZero(value) {
  if (value < 10) {
    return '0'+value
  } 
  return value;
}

let vm = null; 
export default {
  name: 'Notify',
  components: {
    Swiper, SwiperSlide
  },
  data() {
    return {
      swiperOptions:{
        direction: 'vertical',
        autoplay: true,
        loop: true,
        notNextTick: true,
        spaceBetween: 16,
        pagination: {
          el: '.swiper-pagination'
        },
        observer:true,  // 启动动态检查器(OB/观众/观看者)，当改变swiper的样式（例如隐藏/显示）或者修改swiper的子元素时，自动初始化swiper。
        observeParents: true,  // 将observe应用于Swiper的父元素。当Swiper的父元素变化时，例如window.resize，Swiper更新。
        on: {
          click: function() {
            const realIndex = this.realIndex;
            vm.handleClikeSide(realIndex);
          }
        }
      },
      list: []
    }
  },
  computed: {
    ...mapGetters(['jgbm']),
    dateTody() {
      let date = new Date();
      let monthLocal = ['一','二','三','四','五','六','七','八','九','十','十一','十二'];
      return {
        month: monthLocal[date.getMonth()],
        date: addZero(date.getDate())
      }
    }
  },
  methods: {
    handleClikeSide(index) {
      let data = this.list[index];
      this.$router.push('/notify/'+ data.xxid);
    },
    fetchData() {
      const orderBy = "";
      const pageNum = 1,
            pageSize = 5;
      const xxlb = '1';
      // jgbm,xxlb,xxzl,fbbzwxh,fbbzapp,fbbzyyt,orderBy,pageNum,pageSize
      getHlwFbxxPageByJgbm(this.jgbm, xxlb, null, '1', null, null,orderBy,pageNum,pageSize).then(res => {
        console.log(res);
        if (res.status == 200) {
          if (res.data.total == 0) {
            this.list = [];
          } else {
            this.list = res.data.data;
          }
        }
      }).catch(err => {
        console.log(err);
      });
    }
  },
  mounted() {
    vm = this;
  },
  watch: {
    jgbm: {
      handler(val, oldVal) {
        if (val && val != oldVal) {
          this.fetchData();
        }
      },
      immediate: true
    }
  },
}
</script>
<style lang="scss" scoped>
  @import 'src/styles/variable.scss';
  .notify-box {
    display: flex;
    align-items: center;
    box-shadow: 1px 2px 5px $shadow-color;
    margin: 10px 0;
    padding: 16px;
    border-radius: $border-radius-large;
  }
  .notify__date--area {
    width: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .notify__day {
    font-size: 20px;
    font-weight: bold;
  }
  .notify__month {
    font-size: 14px;
    color: $color-gray-400;
  }
  .notify-driver {
    width: 1px;
    height: 36px;
    margin: 0 16px;
    transform: scaleX(0.5);
    background-color: $color-gray-600;
  }

  .notify-box /deep/ .swiper-container {
    flex: 1;
    width: 0;
    height: 40px;
  }

  .notify-swiper-item {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .notify-swiper-content /deep/ {
    cursor: pointer;
    width: 100%;
    h2 {
      font-size: 15px;
      font-weight: bold;
      margin: 0;
      line-height: 1.6;
    }
    p{
      font-size: 12px;
      line-height: 20px;
      margin: 0;
      color: $color-gray-300;
    }
  }
</style>