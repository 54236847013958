<template>
  <div class="news-list">
    <news-item v-for="(item, idx) in news" :key="idx" :data-source="item"/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import { getHlwFbxxPageByJgbm } from '@/api/login'

import NewsItem from './news-item'
export default {
  name: 'NewsList',
  components: {
    NewsItem
  },
  data() {
    return {
      news: [
        // {
        //   coverImage: 'https://www.csg.cn/xwzx/2020/yxcz/202101/W020210115341519367851.jpg',
        //   title: '贵州电网公司启动全面提升“获得电力”服务水平持续优化用电营商环境三年行动做实“三零”“三省” 打造特色品牌',
        //   desc: '近日，贵州电网公司发布《全面提升“获得电力”服务水平持续优化用电营商环境三年行动方案》(以下简称《行动方案》)，立足用户需求，致力于构建现代供电服务体系，努力为用户提供可靠、便捷、高效、智慧的新型供电服务，切实增强用户的获得感、幸福感和安全感，全力营造良好用电营商环境。'
        // },
        // {
        //   coverImage: 'https://www.csg.cn/xwzx/2020/mtgz/202101/W020210115593499657300.jpg',
        //   title: '央视：贵州电网覆冰 南方电网工作者们奋战在抗击凝冻灾害第一线',
        //   desc: '超强冷空气席卷云贵高原，给群众的生产生活带来严重影响，贵州启动Ⅳ级应急响应。'
        // }
      ]
    }
  },
  computed: {
    ...mapGetters(['jgbm']),
  },
  methods: {
    fetchData() {
      const orderBy = "";
      const pageNum = 1,
            pageSize = 10;
      // jgbm,xxlb,xxzl,fbbzwxh,fbbzapp,fbbzyyt,orderBy,pageNum,pageSize
      getHlwFbxxPageByJgbm(this.jgbm, '2', null, '1', null, null,orderBy,pageNum,pageSize).then(res => {
        console.log(res);
        if (res.status == 200) {
          if (res.data.total == 0) {
            this.news = [];
          } else {
            this.news = res.data.data;
          }
        }
      }).catch(err => {
        console.log(err);
      })
    }
  },
  mounted() {
  },
  watch: {
    jgbm: {
      handler(val, oldVal) {
        if (val && val != oldVal) {
          this.fetchData();
        }
      },
      immediate: true
    }
  },
}
</script>

<style lang="scss" scoped>
  .news-list {
    margin: 15px 0;
  }
</style>