<template lang="">
    <div class="news-item" gutter="20" @click.stop.prevent="itemClickHandle">
      <div class="news-item__image-box">
        <van-image lazy-load fit="cover" width="108" height="68" radius="4" :src="dataSource.tpdz" />
      </div>
      <div class="news-item__content">
        <h2 class="news-item__title van-ellipsis">{{dataSource.xxbt}}</h2>
        <p class="news-item__desc van-multi-ellipsis--l2">{{dataSource.xxfbt}}</p>
      </div>
    </div>
</template>


<script>
  export default {
    name: 'NewsItem',
    props: {
      dataSource: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {

      }
    },
    methods: {
      itemClickHandle() {
        this.$router.push('/news/'+ this.dataSource.xxid)
      }
    },
  }
</script>

<style lang="scss" scoped>
  .news-item {
    display: flex;
    margin-bottom: 10px;
    cursor: pointer;
  }
  .news-item__image-box {
    margin-right: 16px;
    border: 1px solid #eee;
    border-radius: 4px;
    font-size: 0;
  }
  .news-item__content {
    flex: 1;
    width: 0;
  }
  .news-item__title {
    font-size: 15px;
    font-weight: bold;
    line-height: 1.5;
  }

  .news-item__desc {
    font-size: 12px;
    line-height: 1.5;
    margin: 5px 0;
  }
</style>