<template>
  <div class="nav-bar">
    <div class="nav-bar__content">
      <div class="nav-bar__left"></div>
      <div class="nav-bar__center">
        <Logo />
      </div>
      <div class="nav-bar__right">
        <van-button type="primary" to="my" color="rgba(0,0,0,0)">
          <van-icon :name="personIcon" size="24"/>
        </van-button>
      </div>
    </div>

  </div>
</template>

<script>
import Logo from '@/components/Logo'
import personIcon from '@/assets/icons/person.png'
export default {
  name: 'NavBar',
  components: {
    Logo
  },
  data() {
    return {
      personIcon
    }
  },
}
</script>

<style lang="scss" scoped>
  .nav-bar {
    margin: 2rem 0;
    font-size: 16px;
  }
  .nav-bar__content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .nav-bar__center {
    margin: 0 auto;
  }

  .nav-bar__right /deep/{
    padding: 0 16px;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #fff;
    position: absolute;
    right: 0;
    top: 0;
    .van-button {
      padding: 0;
    }
  }
</style>